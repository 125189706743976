<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-27 16:21:05
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2025-03-17 12:41:44
 * @FilePath: \super_admin\src\components\inner\views\liuhepankou.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="liuhepankou fff-light-bg">
        <div class="panel">
            <div class="panel-title">
                <h2>六合盘口</h2>
                <div style="line-height: 25px; text-align: center">
                    <span>当前选择公司：</span>
                    <el-radio-group size="small" v-model="gs.checked" @change="get_list(1)" text-color="#fff" fill="#E6A23C">
                        <el-radio-button v-for="item in gs.list" :key="item.username" :label="item.username">{{ item.name }}</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="panel-body">
                <div style="padding: 5px; text-align: left">
                    <el-button v-for="item in btns" :key="item.gid" :type="item.gid == gid ? 'primary' : ''" size="small" @click="change_game(item)">{{ item.name }}</el-button>
                    <el-button style="float: right" type="primary" size="small" @click="add_pk">+ 手动新增盘口</el-button>
                </div>
                <el-table :data="list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                    <el-table-column prop="gname" label="彩种" align="center" width="90"></el-table-column>
                    <el-table-column prop="qnum" label="期号" align="center" width="85"></el-table-column>
                    <el-table-column prop="starttime" label="开盘时间" align="center" width="180">
                        <template #default="scope">
                            <span>{{ $filters.time(scope.row.starttime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="closetime" label="非特码封盘时间" align="center" width="180">
                        <template #default="scope">
                            <span>{{ $filters.time(scope.row.closetime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="tema_closetime" label="特码封盘时间" align="center" width="180">
                        <template #default="scope">
                            <span>{{ $filters.time(scope.row.tema_closetime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="res" label="开奖号码" align="left">
                        <template #default="scope">
                            <template v-if="gid != 10">
                                <span v-for="item in scope.row.res" :key="item.index">
                                    <span class="nums-lhc" :style="{ backgroundColor: item.color }">{{ item.num }}</span>
                                    <span>{{ item.sx }}</span>
                                </span>
                            </template>
                            <template v-else>
                                <span class="pl5-num" v-for="item in scope.row.res" :key="item.index">{{ item }}</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" align="center" width="70">
                        <template #default="scope">
                            <span>{{ scope.row.status }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="settle_status" label="结算状态" align="center" width="70">
                        <template #default="scope">
                            <span>{{ scope.row.settle_status }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="open_control" label="开盘" align="center" width="70">
                        <template #default="scope">
                            <span>{{ scope.row.open_control }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop label="操作" align="center" width="160">
                        <template #default="scope">
                            <a class="a-icon i-Edit theme-text" href="#" @click.prevent="open_js(scope.row)">结算</a>
                            <template v-if="scope.row.status == '未开盘'">
                                <a class="a-icon i-tuishui theme-text" href="#" @click.prevent="change_row(scope.row)">修改</a>
                                <a class="a-icon i-cashOut theme-text" href="#" @click.prevent="del_row(scope.row)">删除</a>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="paging" style="padding: 10px 0">
                    <el-pagination small @current-change="get_list" v-model="page" :page-size="10" :pager-count="5" :total="pagination.total_records * 1" layout="total,prev,pager,next,jumper" prev-text="上一页" next-text="下一页"></el-pagination>
                </div>
            </div>
        </div>
        <!-- 新增 -->
        <el-dialog v-model="add.show" width="500px" class="mydialogclass">
            <template #header>
                <span class="mydialogtitle">新增盘口</span>
            </template>
            <div class="myform">
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">期数</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-input style="width: 140px" v-model.trim="add.qnum" size="small" placeholder="期数" clearable />
                        <!-- <el-input style="width:140px" v-model.trim="add.qnum" size="small" placeholder="期数" clearable type="number" :min="add.min_qnum" :max="9999999" /> -->
                    </el-col>
                    <el-col class="myform-item-lable" :span="6">默认开盘时间</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-date-picker style="width: 120px" v-model="add.startdate" type="date" placeholder="选择日期" size="small" />
                        <span>&nbsp;-&nbsp;</span>
                        <el-time-picker style="width: 120px" v-model="add.starttime" arrow-control placeholder="选择时间" size="small" />
                    </el-col>
                    <el-col class="myform-item-lable" :span="6">特码封盘时间</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-date-picker style="width: 120px" v-model="add.tema_closedate" type="date" placeholder="选择日期" size="small" />
                        <span>&nbsp;-&nbsp;</span>
                        <el-time-picker style="width: 120px" v-model="add.tema_closetime" arrow-control placeholder="选择时间" size="small" />
                    </el-col>
                    <el-col class="myform-item-lable" :span="6">非特码封盘时间</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-date-picker style="width: 120px" v-model="add.closedate" type="date" placeholder="选择日期" size="small" />
                        <span>&nbsp;-&nbsp;</span>
                        <el-time-picker style="width: 120px" v-model="add.closetime" arrow-control placeholder="选择时间" size="small" />
                    </el-col>
                </el-row>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="add.show = false">取消</el-button>
                    <el-button type="primary" @click="add_pk_sub">确定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog v-model="change.show" width="500px" class="mydialogclass">
            <template #header>
                <span class="mydialogtitle">修改盘口</span>
            </template>
            <div class="myform">
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">期数</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-input style="width: 140px" v-model.trim="change.qnum" size="small" placeholder="期数" clearable />
                        <!-- <el-input style="width:140px" v-model.trim="add.qnum" size="small" placeholder="期数" clearable type="number" :min="add.min_qnum" :max="9999999" /> -->
                    </el-col>
                    <el-col class="myform-item-lable" :span="6">默认开盘时间</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-date-picker style="width: 120px" v-model="change.startdate" type="date" placeholder="选择日期" size="small" />
                        <span>&nbsp;-&nbsp;</span>
                        <el-time-picker style="width: 120px" v-model="change.starttime" arrow-control placeholder="选择时间" size="small" />
                    </el-col>
                    <el-col class="myform-item-lable" :span="6">特码封盘时间</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-date-picker style="width: 120px" v-model="change.tema_closedate" type="date" placeholder="选择日期" size="small" />
                        <span>&nbsp;-&nbsp;</span>
                        <el-time-picker style="width: 120px" v-model="change.tema_closetime" arrow-control placeholder="选择时间" size="small" />
                    </el-col>
                    <el-col class="myform-item-lable" :span="6">非特码封盘时间</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-date-picker style="width: 120px" v-model="change.closedate" type="date" placeholder="选择日期" size="small" />
                        <span>&nbsp;-&nbsp;</span>
                        <el-time-picker style="width: 120px" v-model="change.closetime" arrow-control placeholder="选择时间" size="small" />
                    </el-col>
                </el-row>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="change.show = false">取消</el-button>
                    <el-button type="primary" @click="change_pk_sub">确定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 结算 -->
        <el-dialog v-model="jiesuan.show" width="300px" class="mydialogclass">
            <template #header>
                <span class="mydialogtitle">结算：{{ jiesuan.row.gname }}-{{ jiesuan.row.qnum }}</span>
            </template>
            <el-input style="width: 200px; margin-bottom: 10px" v-model.trim="jiesuan.firstCode" size="small" clearable>
                <template #prepend>
                    <span style="color: #000">第一球</span>
                </template>
                <template #append>
                    <el-button style="color: #000" type="primary" size="small" @click="send_one">发送</el-button>
                </template>
            </el-input>
            <template v-if="gid != 10">
                <span class="nums-lhc" :style="{ 'vertical-align': 'top', marginLeft: '20px', backgroundColor: lhcNum(jiesuan.firstCode, '').color }">{{ jiesuan.firstCode }}</span>
                <span style="vertical-align: super; margin-left: 3px">{{ get_lhc_sx("开奖", [lhcNum(jiesuan.firstCode, $filters.time(new Date().getTime() / 1000, 'notime'))])[0].sx }}</span>
            </template>
            <el-input style="width: 200px; margin-bottom: 10px" v-model.trim="jiesuan.secondCode" size="small" clearable>
                <template #prepend>
                    <span style="color: #000">第二球</span>
                </template>
                <template #append>
                    <el-button style="color: #000" type="primary" size="small" @click="send_one">发送</el-button>
                </template>
            </el-input>
            <template v-if="gid != 10">
                <span class="nums-lhc" :style="{ 'vertical-align': 'top', marginLeft: '20px', backgroundColor: lhcNum(jiesuan.secondCode, '').color }">{{ jiesuan.secondCode }}</span>
                <span style="vertical-align: super; margin-left: 3px">{{ get_lhc_sx("开奖", [lhcNum(jiesuan.secondCode, $filters.time(new Date().getTime() / 1000, 'notime'))])[0].sx }}</span>
            </template>
            <el-input style="width: 200px; margin-bottom: 10px" v-model.trim="jiesuan.thirdCode" size="small" clearable>
                <template #prepend>
                    <span style="color: #000">第三球</span>
                </template>
                <template #append>
                    <el-button style="color: #000" type="primary" size="small" @click="send_one">发送</el-button>
                </template>
            </el-input>
            <template v-if="gid != 10">
                <span class="nums-lhc" :style="{ 'vertical-align': 'top', marginLeft: '20px', backgroundColor: lhcNum(jiesuan.thirdCode, '').color }">{{ jiesuan.thirdCode }}</span>
                <span style="vertical-align: super; margin-left: 3px">{{ get_lhc_sx("开奖", [lhcNum(jiesuan.thirdCode, $filters.time(new Date().getTime() / 1000, 'notime'))])[0].sx }}</span>
            </template>
            <el-input style="width: 200px; margin-bottom: 10px" v-model.trim="jiesuan.fourthCode" size="small" clearable>
                <template #prepend>
                    <span style="color: #000">第四球</span>
                </template>
                <template #append>
                    <el-button style="color: #000" type="primary" size="small" @click="send_one">发送</el-button>
                </template>
            </el-input>
            <template v-if="gid != 10">
                <span class="nums-lhc" :style="{ 'vertical-align': 'top', marginLeft: '20px', backgroundColor: lhcNum(jiesuan.fourthCode, '').color }">{{ jiesuan.fourthCode }}</span>
                <span style="vertical-align: super; margin-left: 3px">{{ get_lhc_sx("开奖", [lhcNum(jiesuan.fourthCode, $filters.time(new Date().getTime() / 1000, 'notime'))])[0].sx }}</span>
            </template>
            <el-input style="width: 200px; margin-bottom: 10px" v-model.trim="jiesuan.fifthCode" size="small" clearable>
                <template #prepend>
                    <span style="color: #000">第五球</span>
                </template>
                <template #append>
                    <el-button style="color: #000" type="primary" size="small" @click="send_one">发送</el-button>
                </template>
            </el-input>
            <template v-if="gid != 10">
                <span class="nums-lhc" :style="{ 'vertical-align': 'top', marginLeft: '20px', backgroundColor: lhcNum(jiesuan.fifthCode, '').color }">{{ jiesuan.fifthCode }}</span>
                <span style="vertical-align: super; margin-left: 3px">{{ get_lhc_sx("开奖", [lhcNum(jiesuan.fifthCode, $filters.time(new Date().getTime() / 1000, 'notime'))])[0].sx }}</span>
            </template>
            <template v-if="gid != 10">
                <el-input style="width: 200px; margin-bottom: 10px" v-model.trim="jiesuan.sixCode" size="small" clearable>
                    <template #prepend>
                        <span style="color: #000">第六球</span>
                    </template>
                    <template #append>
                        <el-button style="color: #000" type="primary" size="small" @click="send_one">发送</el-button>
                    </template>
                </el-input>
                <span class="nums-lhc" :style="{ 'vertical-align': 'top', marginLeft: '20px', backgroundColor: lhcNum(jiesuan.sixCode, '').color }">{{ jiesuan.sixCode }}</span>
                <span style="vertical-align: super; margin-left: 3px">{{ get_lhc_sx("开奖", [lhcNum(jiesuan.sixCode, $filters.time(new Date().getTime() / 1000, 'notime'))])[0].sx }}</span>
                <el-input style="width: 200px; margin-bottom: 10px" v-model.trim="jiesuan.sevenCode" size="small" clearable>
                    <template #prepend>
                        <span style="color: #000">第七球</span>
                    </template>
                    <template #append>
                        <el-button style="color: #000" type="primary" size="small" @click="send_one">发送</el-button>
                    </template>
                </el-input>
                <span class="nums-lhc" :style="{ 'vertical-align': 'top', marginLeft: '20px', backgroundColor: lhcNum(jiesuan.sevenCode, '').color }">{{ jiesuan.sevenCode }}</span>
                <span style="vertical-align: super; margin-left: 3px; margin-left: 3px">{{ get_lhc_sx("开奖", [lhcNum(jiesuan.sevenCode, $filters.time(new Date().getTime() / 1000))])[0].sx }}</span>
                <div style="text-align: center; color: red">号码1-49 不可重复</div>
            </template>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="jiesuan.show = false">取消</el-button>
                    <el-button type="primary" @click="sub_jiesuan">全部提交</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import axios from "../../../axios/axios";
import { lhcNum } from "../../../plugin/lhcNum";
import { get_lhc_sx } from "../../../plugin/sx";
export default {
    components: {},
    data() {
        return {
            gid: "20",
            btns: [
                { type: "primary", gid: 20, name: "六合彩" },
                { type: "", gid: 34, name: "澳门六合彩" },
                { type: "", gid: 49, name: "新澳门六合彩" },
                { type: "", gid: 50, name: "台湾六合彩" },
                { type: "", gid: 51, name: "快乐8六合彩" },
                { type: "", gid: 10, name: "排列五" },
                { type: "", gid: 53, name: "排列三" },
                { type: "", gid: 4, name: "福彩3D" },
            ],
            list: [],
            page: 1,
            pagination: {
                //分页信息
                total_pages: "",
                total_records: "",
                page_size: "",
            },
            change: {
                show: false,
                act: "edit",
                id: "",
                qnum: "",
                today: new Date(),
                startdate: "",
                starttime: "16:00:00",
                closedate: "",
                closetime: "21:30:00",
                tema_closedate: "",
                tema_closetime: "21:31:00",
            },
            add: {
                show: false,
                act: "edit",
                qnum: "",
                today: new Date(),
                startdate: "",
                starttime: "16:00:00",
                closedate: "",
                closetime: "21:30:00",
                tema_closedate: "",
                tema_closetime: "21:31:00",
            },
            jiesuan: {
                show: false,
                act: "open",
                row: "",
                num_list: "",
                firstCode: "",
                secondCode: "",
                thirdCode: "",
                fourthCode: "",
                fifthCode: "",
                sixCode: "",
                sevenCode: "",
            },
            lhcNum: "",
            get_lhc_sx: "",
            gs: {
                visible: false,
                list: [],
                checked: "",
                pagination: { total_pages: 0, total_records: "0", page_size: 10 },
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        // console.log(to.query.db);
        axios
            .post("play/num_list", {
                page: 1,
                gid: to.query.gid,
                start_time: "",
                end_time: "",
                db: to.query.db,
            })
            .then((result) => {
                if (result.data.status == 200) {
                    next((vm) => {
                        // 通过 `vm` 访问组件实例
                        vm.list = result.data.repsoneContent.list;
                        vm.pagination = result.data.repsoneContent.pagination;
                        for (let i = 0; i < vm.list.length; i++) {
                            let arr = [];
                            for (let j = 0; j < vm.list[i].res.length; j++) {
                                arr.push(lhcNum(lhcNum(vm.list[i].res[j], vm.$filters.time(vm.list[i].starttime).split(" ")[0])));
                            }
                            vm.list[i].res = get_lhc_sx("开奖", arr);
                        }
                        if (vm.list.length > 0) {
                            vm.add.qnum = vm.add.min_qnum = Number(vm.list[0].qnum) + 1;
                        }
                        vm.gs.list = vm.$store.state.gs.list;
                        vm.gs.checked = to.query.db ? to.query.db : vm.gs.list[0].name;
                        vm.btns = vm.$store.state.userinfo.lh_list;
                        vm.gid = to.query.gid;
                    });
                }
            });
    },
    created() {
        this.lhcNum = lhcNum;
        this.get_lhc_sx = get_lhc_sx;
        // console.log(this.$store.state.userinfo);
    },
    methods: {
        send_one() {
            this.sub_jiesuan("one");
        },
        sub_jiesuan(type = "all") {
            let list = [this.jiesuan.firstCode, this.jiesuan.secondCode, this.jiesuan.thirdCode, this.jiesuan.fourthCode, this.jiesuan.fifthCode, this.jiesuan.sixCode, this.jiesuan.sevenCode];
            let nary = list.sort();
            for (let i = 0; i < list.length; i++) {
                if (nary[i] != "") {
                    if (Number.isNaN(Number(nary[i] * 1))) {
                        return this.$message.error("请填写正确的号码");
                    }
                    if (this.gid != 10 && (nary[i] < 1 || nary[i] > 49)) {
                        return this.$message.error("六合号码为1-49");
                    }
                    if (this.gid != 10 && nary[i] == nary[i + 1]) {
                        return this.$message.error("号码不可重复");
                    }
                }
            }
            // return console.log(list);
            this.axios
                .post("play/num_edit", {
                    gid: this.gid,
                    db: this.gs.checked,
                    ...this.jiesuan,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.$message.success(result.data.shortMessage);
                        if (type == "all") {
                            this.jiesuan.show = false;
                        }
                        this.get_list(1);
                    }
                });
        },
        reset_code() {
            this.jiesuan.firstCode = "";
            this.jiesuan.secondCode = "";
            this.jiesuan.thirdCode = "";
            this.jiesuan.fourthCode = "";
            this.jiesuan.fifthCode = "";
            this.jiesuan.sixCode = "";
            this.jiesuan.sevenCode = "";
        },
        open_js(row) {
            if (row.settle_status == "已结算") {
                return this.$message.error("当期已结算");
            }
            this.reset_code();
            this.axios
                .post("play/num_edit", {
                    act: "res",
                    gid: this.gid,
                    db: this.gs.checked,
                    ...row,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        if (result.data.repsoneContent.list[0] != undefined) {
                            let list = result.data.repsoneContent.list[0].res;
                            if (list[0] != undefined) {
                                this.jiesuan.firstCode = list[0];
                            }
                            if (list[1] != undefined) {
                                this.jiesuan.secondCode = list[1];
                            }
                            if (list[2] != undefined) {
                                this.jiesuan.thirdCode = list[2];
                            }
                            if (list[3] != undefined) {
                                this.jiesuan.fourthCode = list[3];
                            }
                            if (list[4] != undefined) {
                                this.jiesuan.fifthCode = list[4];
                            }
                            if (list[5] != undefined) {
                                this.jiesuan.sixCode = list[5];
                            }
                            if (list[6] != undefined) {
                                this.jiesuan.sevenCode = list[6];
                            }
                        }
                        this.jiesuan.row = row;
                        this.jiesuan.num_list = row.id;
                        this.jiesuan.show = true;
                    }
                });
        },
        //删除
        del_row(row) {
            this.$confirm(`确定删除 ${row.qnum} 期吗?`, "提示", {
                confirmButtonText: "确定删除",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.axios
                        .post("play/num_edit", {
                            act: "del",
                            id: row.id,
                            gid: this.gid,
                            qnum: row.qnum,
                            db: this.gs.checked,
                        })
                        .then((result) => {
                            if (result.data.status == 200) {
                                this.$message.success(result.data.shortMessage);
                                this.get_list(1);
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        },
        //修改
        change_row(row) {
            // console.log(row);
            this.change.id = row.id;
            this.change.qnum = row.qnum;
            this.change.startdate = this.$filters.time(row.starttime, "notime");
            this.change.closedate = this.$filters.time(row.closetime, "notime");
            this.change.tema_closedate = this.$filters.time(row.tema_closetime, "notime");
            this.change.starttime = `${this.change.startdate} ${this.$filters.time(row.starttime, "nodate")}`;
            this.change.closetime = `${this.change.closedate} ${this.$filters.time(row.closetime, "nodate")}`;
            this.change.tema_closetime = `${this.change.tema_closedate} ${this.$filters.time(row.tema_closetime, "nodate")}`;
            this.change.show = true;
        },
        //提交修改
        change_pk_sub() {
            let obj = {
                act: "edittime",
                id: this.change.id,
                qnum: "",
                starttime: "",
                closetime: "",
                tema_closetime: "",
                today: this.$filters.time(Date.parse(new Date()) / 1000),
                gid: this.gid,
                db: this.gs.checked,
            };
            // console.log(this.add);
            obj.qnum = this.change.qnum;
            obj.starttime = `${this.$filters.time(Date.parse(this.change.startdate) / 1000, "notime")} ${this.$filters.time(Date.parse(this.change.starttime) / 1000).split(" ")[1]}`;
            obj.closetime = `${this.$filters.time(Date.parse(this.change.closedate) / 1000, "notime")} ${this.$filters.time(Date.parse(this.change.closetime) / 1000).split(" ")[1]}`;
            obj.tema_closetime = `${this.$filters.time(Date.parse(this.change.tema_closedate) / 1000, "notime")} ${this.$filters.time(Date.parse(this.change.tema_closetime) / 1000).split(" ")[1]}`;
            // console.log(obj);
            this.axios.post("play/num_edit", obj).then((result) => {
                if (result.data.status == 200) {
                    this.$message.success(result.data.shortMessage);
                    this.change.show = false;
                    this.get_list(1);
                }
            });
        },
        ///提交新增
        add_pk_sub() {
            // console.log(this.add);
            let obj = {
                act: "edit",
                qnum: "",
                starttime: "",
                closetime: "",
                tema_closetime: "",
                today: this.$filters.time(Date.parse(new Date()) / 1000),
                gid: this.gid,
                db: this.gs.checked,
            };
            // console.log(this.add);
            obj.qnum = this.add.qnum;
            obj.starttime = `${this.$filters.time(Date.parse(this.add.startdate) / 1000, "notime")} ${this.$filters.time(Date.parse(this.add.starttime) / 1000).split(" ")[1]}`;
            obj.closetime = `${this.$filters.time(Date.parse(this.add.closedate) / 1000, "notime")} ${this.$filters.time(Date.parse(this.add.closetime) / 1000).split(" ")[1]}`;
            obj.tema_closetime = `${this.$filters.time(Date.parse(this.add.tema_closedate) / 1000, "notime")} ${this.$filters.time(Date.parse(this.add.tema_closetime) / 1000).split(" ")[1]}`;
            // console.log(obj);
            this.axios.post("play/num_edit", obj).then((result) => {
                if (result.data.status == 200) {
                    this.$message.success(result.data.shortMessage);
                    this.add.show = false;
                    this.get_list(1);
                }
            });
        },
        //新增
        add_pk() {
            this.add.today = new Date();
            let day = this.$filters.time(Date.parse(this.add.today) / 1000, "notime");
            // console.log(day);
            this.add.startdate = day;
            this.add.closedate = day;
            this.add.tema_closedate = day;
            this.add.starttime = `${this.add.startdate} 16:00:00`;
            this.add.closetime = `${this.add.closedate} 21:30:00`;
            this.add.tema_closetime = `${this.add.tema_closedate} 21:31:00`;
            this.add.show = true;
        },
        change_game(item) {
            this.add.qnum = "";
            this.get_list(1, item.gid);
        },
        get_list(page, gid) {
            this.page = page;
            this.axios
                .post("play/num_list", {
                    page: page,
                    gid: gid,
                    start_time: "",
                    end_time: "",
                    db: this.gs.checked,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.list = result.data.repsoneContent.list;
                        this.pagination = result.data.repsoneContent.pagination;
                        if (gid != 10) {
                            for (let i = 0; i < this.list.length; i++) {
                                let arr = [];
                                for (let j = 0; j < this.list[i].res.length; j++) {
                                    arr.push(lhcNum(lhcNum(this.list[i].res[j], this.$filters.time(this.list[i].starttime).split(" ")[0])));
                                }
                                this.list[i].res = get_lhc_sx("开奖", arr);
                            }
                        }
                        if (this.page == 1) {
                            this.add.qnum = this.add.min_qnum = Number(this.list[0].qnum) + 1;
                        }
                        this.gid = gid;
                    }
                });
        },
    },
};
</script>

<style scoped>
.nums-lhc {
    display: inline-block;
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin: 0;
    margin-left: 2px;
    color: #fff;
    text-align: center;
}
</style>