<!--
 * @Author: your name
 * @Date: 2022-03-11 16:35:56
 * @LastEditTime: 2025-03-17 13:28:16
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\views\Login.vue
-->
<template>
    <div class="login" :style="{ width: screenwidth }">
        <div style="background-color: #fff; height: 3px"></div>
        <!-- <div class="logintitle">WELCOME</div> -->
        <div class="loginbox" v-if="show_view == 'login'" :style="{ width: form.user.platform == 'pc' ? '600px' : 'auto' }">
            <form name="form_login" id="form_login" onsubmit="return CheckForm();" method="post">
                <div class="logintitle">WELCOME</div>
                <div class="shenfen" :style="{ padding: form.user.platform == 'pc' ? '' : '0' }">
                    <el-divider>
                        <span style="font-size: 34px; color: #000; font-weight: 800">总后台登录</span>
                    </el-divider>
                    <!-- <img src="../assets/manager.png" />
                    <input type="hidden" name="action" value="login" />
                    <input type="hidden" name="v1" value="c0fb3f85a5b70519029c75a89c92477f" />
                    <input type="hidden" name="v2" value /> -->
                </div>
                <!-- <div class="shenfen">
                    <img src="../assets/manager_tit.png" />
                </div> -->
                <div class="usemsg" :style="{ width: form.user.platform == 'pc' ? '450px' : 'auto' }">
                    <div class="user">
                        <div class="img-box">
                            <!-- <img src="../assets/user.png" /> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M9.3335 24V22.6667C9.3335 20.8986 10.0359 19.2029 11.2861 17.9526C12.5364 16.7024 14.2321 16 16.0002 16C17.7683 16 19.464 16.7024 20.7142 17.9526C21.9644 19.2029 22.6668 20.8986 22.6668 22.6667V24"
                                    stroke="#303030" stroke-width="1.5" stroke-linecap="round" />
                                <path
                                    d="M16 16C17.0609 16 18.0783 15.5786 18.8284 14.8284C19.5786 14.0783 20 13.0609 20 12C20 10.9391 19.5786 9.92172 18.8284 9.17157C18.0783 8.42143 17.0609 8 16 8C14.9391 8 13.9217 8.42143 13.1716 9.17157C12.4214 9.92172 12 10.9391 12 12C12 13.0609 12.4214 14.0783 13.1716 14.8284C13.9217 15.5786 14.9391 16 16 16Z"
                                    stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9998 29.3333C23.3636 29.3333 29.3332 23.3638 29.3332 16C29.3332 8.6362 23.3636 2.66666 15.9998 2.66666C8.63604 2.66666 2.6665 8.6362 2.6665 16C2.6665 23.3638 8.63604 29.3333 15.9998 29.3333Z"
                                    stroke="#303030" stroke-width="1.5" />
                            </svg>
                        </div>
                        <div class="input-box">
                            <input v-model="form.user.user_name" type="text" id="user" name="user" tabindex="1" autocomplete="off" maxlength="16" placeholder="请输入用户名" />
                        </div>
                    </div>
                    <div class="user">
                        <div class="img-box">
                            <!-- <img src="../assets/pwd.png" /> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path
                                    d="M20.3125 9.75H19.5V6.64909C19.5 2.92053 16.8021 0 12.9699 0C9.12234 0 6.5 2.98269 6.5 6.64909V9.75H5.6875C3.89512 9.75 2.4375 11.2076 2.4375 13V22.75C2.4375 24.5424 3.89512 26 5.6875 26H20.3125C22.1049 26 23.5625 24.5424 23.5625 22.75V13C23.5625 11.2076 22.1049 9.75 20.3125 9.75ZM8.125 6.64909C8.125 3.87928 10.0181 1.625 12.9699 1.625C15.8913 1.625 17.875 3.83175 17.875 6.64909V9.75H8.125V6.64909ZM21.9375 22.75C21.9375 23.6458 21.2083 24.375 20.3125 24.375H5.68753C4.79174 24.375 4.06253 23.6458 4.06253 22.75V13C4.06253 12.1042 4.79174 11.375 5.68753 11.375H20.3125C21.2083 11.375 21.9375 12.1042 21.9375 13V22.75ZM13 14.625C12.1026 14.625 11.375 15.3526 11.375 16.25C11.375 16.85 11.7041 17.368 12.1875 17.6495V20.3125C12.1875 20.761 12.5515 21.125 13 21.125C13.4485 21.125 13.8125 20.761 13.8125 20.3125V17.6495C14.296 17.368 14.625 16.8496 14.625 16.25C14.625 15.3526 13.8974 14.625 13 14.625Z"
                                    fill="#303030" />
                            </svg>
                        </div>
                        <div class="input-box">
                            <input v-model="form.user.passwd" type="password" id="pass" name="pass" tabindex="2" autocomplete="off" maxlength="16" placeholder="请输入密码" />
                        </div>
                    </div>
                    <div class="user" v-show="error_times >= 3">
                        <div class="img-box">
                            <!-- <img src="../assets/code.png" /> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path
                                    d="M12.6875 3.9375H4.375C4.13437 3.9375 3.9375 4.13437 3.9375 4.375V12.6875C3.9375 12.9281 4.13437 13.125 4.375 13.125H12.6875C12.9281 13.125 13.125 12.9281 13.125 12.6875V4.375C13.125 4.13437 12.9281 3.9375 12.6875 3.9375ZM11.2656 11.2656H5.79688V5.79688H11.2656V11.2656ZM23.625 3.9375H15.3125C15.0719 3.9375 14.875 4.13437 14.875 4.375V12.6875C14.875 12.9281 15.0719 13.125 15.3125 13.125H23.625C23.8656 13.125 24.0625 12.9281 24.0625 12.6875V4.375C24.0625 4.13437 23.8656 3.9375 23.625 3.9375ZM22.2031 11.2656H16.7344V5.79688H22.2031V11.2656ZM12.6875 14.875H4.375C4.13437 14.875 3.9375 15.0719 3.9375 15.3125V23.625C3.9375 23.8656 4.13437 24.0625 4.375 24.0625H12.6875C12.9281 24.0625 13.125 23.8656 13.125 23.625V15.3125C13.125 15.0719 12.9281 14.875 12.6875 14.875ZM11.2656 22.2031H5.79688V16.7344H11.2656V22.2031ZM23.625 14.875H15.3125C15.0719 14.875 14.875 15.0719 14.875 15.3125V23.625C14.875 23.8656 15.0719 24.0625 15.3125 24.0625H23.625C23.8656 24.0625 24.0625 23.8656 24.0625 23.625V15.3125C24.0625 15.0719 23.8656 14.875 23.625 14.875ZM22.2031 22.2031H16.7344V16.7344H22.2031V22.2031Z"
                                    fill="#222222" />
                            </svg>
                        </div>
                        <div class="code1">
                            <input v-model="form.user.checkcode" type="text" id="code" name="code" tabindex="3" autocomplete="off" maxlength="4" placeholder="请输入验证码" />
                        </div>
                        <div class="code2">
                            <img id="pic_code" :src="codeimg" align="middle" title="点击更换验证码" @click="getcode" />
                        </div>
                    </div>
                    <!-- DJ平台验证 -->
                    <!-- <el-radio-group v-model="form.user.type">
                            <el-radio label="email">邮箱验证</el-radio>
                            <el-radio label="google">谷歌验证</el-radio>
                        </el-radio-group> -->
                    <div class="user" v-if="show_email_code">
                        <!-- <template v-if="form.user.type == 'email'">
                            <el-input v-model="form.user.auth" placeholder="请输入邮箱收到的验证码">
                                <template #prepend>邮箱验证码</template>
<template #append>
                                    <el-button v-if="exp_time == 0" type="success" @click="get_email_code">
                                        <span>{{ get_emailcode_btn_text }}</span>
                                    </el-button>
                                    <el-button v-else type="success" disabled>{{ exp_time }}秒后获取</el-button>
                                </template>
</el-input>
</template> -->
                        <template v-if="form.user.type == 'google'">
                            <!-- <el-input v-model="form.user.auth" placeholder="请输二次验证码">
                                <template #prepend>二次验证码</template>
</el-input> -->
                            <div class="img-box label-box">
                                <div class="auth-label">二次验证码</div>
                            </div>
                            <div class="input-box auth-input">
                                <input type="text" id="auth" tabindex="2" autocomplete="off" maxlength="20" placeholder="请输二次验证码" v-model="form.user.auth" />
                            </div>
                        </template>
                    </div>
                </div>
                <a class="loginbtn">
                    <button type="button" id="login_btn" @click="login">登 录</button>
                </a>
            </form>
        </div>
        <!-- 首次修改密码 -->
        <div v-if="show_view == 'changepass'" class="panel" style="width: 650px">
            <div class="panel-title">
                <h2>首次登录或密码已重置，安全原因，请输入新的登录密码</h2>
            </div>
            <div class="panel-body">
                <div class="myform">
                    <el-row class="myform-item" v-show="first_change_pwd.show_login_name">
                        <el-col class="myform-item-lable" :span="4">虚拟账号</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-popover :visible="err.login_name.visible" placement="right">
                                <template #reference>
                                    <el-input style="width: 140px" v-model.trim="first_change_pwd.login_name" size="small" clearable @focus="err.login_name.visible = false" />
                                </template>
                                <span>
                                    <el-icon color="red" size="20px">
                                        <warning-filled />
                                    </el-icon>
                                    <span>{{ err.login_name.content }}</span>
                                </span>
                            </el-popover>
                            <span>&nbsp;修改成功后请用该虚拟账号进行登录</span>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">旧密码</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-popover :visible="err.txtoldpwd.visible" placement="right">
                                <template #reference>
                                    <el-input style="width: 140px" v-model.trim="first_change_pwd.txtoldpwd" size="small" clearable show-password type="password" @focus="err.txtoldpwd.visible = false" />
                                </template>
                                <span>
                                    <el-icon color="red" size="20px">
                                        <warning-filled />
                                    </el-icon>
                                    <span>{{ err.txtoldpwd.content }}</span>
                                </span>
                            </el-popover>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">新密码</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-popover :visible="err.txtnewpwd.visible" placement="right">
                                <template #reference>
                                    <el-input style="width: 140px" v-model.trim="first_change_pwd.txtnewpwd" size="small" clearable show-password type="password" @focus="err.txtnewpwd.visible = false" />
                                </template>
                                <span>
                                    <el-icon color="red" size="20px">
                                        <warning-filled />
                                    </el-icon>
                                    <span>{{ err.txtnewpwd.content }}</span>
                                </span>
                            </el-popover>
                            <div style="display: inline-block">
                                <span>&nbsp;大写字母、小写字母、数字，4种组合，8-20位</span>
                                <el-popover placement="right" :width="320">
                                    <template #reference>
                                        <el-button style="margin: 0 0 0 10px; width: 35px" type="warning" plain size="small">说明</el-button>
                                    </template>
                                    <div style="color: #000">
                                        <div>小写字母：a-z</div>
                                        <div>大写字母：A-Z</div>
                                        <div>数字：0-9</div>
                                        <div>特殊字符：~!@#$%^&*()_+-?&lt;&gt;:.,</div>
                                        <div style="color: #f56c6c">注：上面可用做密码的特殊字符为英文字符，输入时请注意切换输入法为英文，其他字符将不会通过验证</div>
                                    </div>
                                </el-popover>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">重复新密码</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-popover :visible="err.txtnewpwdcf.visible" placement="right">
                                <template #reference>
                                    <el-input style="width: 140px" v-model.trim="first_change_pwd.txtnewpwdcf" size="small" clearable show-password type="password" @focus="err.txtnewpwdcf.visible = false" />
                                </template>
                                <span>
                                    <el-icon color="red" size="20px">
                                        <warning-filled />
                                    </el-icon>
                                    <span>{{ err.txtnewpwdcf.content }}</span>
                                </span>
                            </el-popover>
                        </el-col>
                    </el-row>
                </div>
                <el-button style="margin-top: 10px" type="danger" size="small" @click="show_view = 'login'">取 消</el-button>
                <el-button style="margin-top: 10px" size="small" type="warning" @click="sub_change">确认修改</el-button>
            </div>
        </div>
        <!-- 绑定邮箱 -->
        <div v-if="show_view == 'email'" class="panel" style="width: 600px">
            <div class="panel-title">
                <h2>为了账号安全原因，请进行邮箱绑定</h2>
            </div>
            <div class="panel-body">
                <div class="myform">
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">选择邮箱类型</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-radio-group v-model="email_type">
                                <el-radio label="qq">QQ邮箱</el-radio>
                                <el-radio label="gmail">谷歌邮箱</el-radio>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">邮箱地址前缀</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-input v-model="f_email" placeholder="请输入邮箱地址前缀" size="small">
                                <template #append>
                                    <span v-if="email_type == 'qq'">@qq.com</span>
                                    <span v-if="email_type == 'gmail'">@gmail.com</span>
                                </template>
                            </el-input>
                        </el-col>
                    </el-row>
                </div>
                <el-button style="margin-top: 10px" @click="show_view = 'login'">取 消</el-button>
                <el-button style="margin-top: 10px" type="warning" @click="bd_email">绑 定</el-button>
            </div>
        </div>
        <!-- 绑定谷歌 -->
        <div v-if="show_view == 'google'" class="panel" :style="{ width: form.user.platform == 'pc' ? '805px' : '99vw' }">
            <div class="panel-title" style="height: 36px">
                <h2>为了账号安全原因，请进行谷歌绑定</h2>
                <el-button style="float: right; margin-top: 2px" type="danger" @click="show_view = 'login'">取 消</el-button>
            </div>
            <div class="panel-body">
                <erciyanzheng ref="erciyanzheng" :platform="form.user.platform" @done_google="show_view = 'login'"></erciyanzheng>
            </div>
        </div>
    </div>
</template>

<script>
import erciyanzheng from "../components/self/views/erciyanzheng.vue";
export default {
    name: "login",
    components: { erciyanzheng },
    props: {},
    data() {
        return {
            show_view: "login", //changepass,//email
            form: {
                user: {
                    user_name: "",
                    passwd: "",
                    checkcode: "",
                    platform: "pc",
                    auth: "",
                    type: "google", //email//google
                },
            },
            year: "",
            first_change_pwd: {
                visible: false,
                txtid: "",
                txtoldpwd: "",
                txtnewpwd: "",
                txtnewpwdcf: "",
                salt: "",
                user_id: "",
                username: "",
                old_password: "",
            },
            err: {
                txtoldpwd: {
                    visible: false,
                    content: "",
                },
                txtnewpwd: {
                    visible: false,
                    content: "",
                },
                txtnewpwdcf: {
                    visible: false,
                    content: "",
                },
            },
            screenwidth: "auto",
            error_times: 0,
            codeimg: "",

            email_type: "qq",
            f_email: "",
            back_user_data: {},
            show_email_code: true,
            exp_time: 0,
            exp_time_loop_id: "",
            get_emailcode_btn_text: "获取",
            qrdata: "",
        };
    },
    created() {
        this.year = new Date().getFullYear();
        if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i)) {
            this.form.user.platform = "mobile";
        } else {
            this.form.user.platform = "pc";
        }
        // console.log(this.form.user.platform);
    },
    methods: {
        login() {
            if (this.error_times >= 3 && this.checkcode == "") {
                return this.$message.error("請輸入驗證碼");
            }
            if (this.form.user.user_name != "" && this.form.user.passwd != "") {
                this.axios.post("login/ajax_login", this.form.user).then((result) => {
                    // console.log(result);
                    if (result.data.status == 200 && result.data.shortMessage === "登录成功") {
                        this.$store.state.isLogin = true;
                        this.$store.state.userinfo = result.data.repsoneContent;
                        this.$store.state.userinfo.user_name = this.form.user.user_name;
                        let group_id = result.data.repsoneContent.group_id;
                        let group_name = "";
                        if (group_id == 1) {
                            group_name = "总公司";
                        }
                        if (group_id == 2) {
                            group_name = "大总监";
                        }
                        if (group_id == 3) {
                            group_name = "总监";
                        }
                        if (group_id == 4) {
                            group_name = "分公司";
                        }
                        if (group_id == 5) {
                            group_name = "大股东";
                        }
                        if (group_id == 6) {
                            group_name = "股东";
                        }
                        if (group_id == 7) {
                            group_name = "总代理";
                        }
                        if (group_id == 8) {
                            group_name = "代理";
                        }
                        this.$store.state.userinfo.group_name = group_name;
                        this.$store.state.url_query_info = "user=" + this.$store.state.userinfo.user_id + "&token=" + this.$store.state.userinfo.token;
                        sessionStorage.setItem("islogin", "true");
                        this.$store.state.watchsub_show = false;
                        this.$store.state.watchsub_sub_id = "";
                        //六合盘口
                        let lh_list = [];
                        if (this.$store.state.userinfo.power != undefined && this.$store.state.userinfo.power != "") {
                            if (this.$store.state.userinfo.power.includes("160")) {
                                lh_list.push({ type: "", gid: 20, name: "六合彩" });
                            }
                            if (this.$store.state.userinfo.power.includes("161")) {
                                lh_list.push({ type: "", gid: 34, name: "澳门六合彩" });
                            }
                            if (this.$store.state.userinfo.power.includes("162")) {
                                lh_list.push({ type: "", gid: 49, name: "新澳门六合彩" });
                            }
                            if (this.$store.state.userinfo.power.includes("163")) {
                                lh_list.push({ type: "", gid: 50, name: "台湾六合彩" });
                            }
                            if (this.$store.state.userinfo.power.includes("177")) {
                                lh_list.push({ type: "", gid: 51, name: "快乐8六合彩" });
                            }
                            if (this.$store.state.userinfo.power.includes("180")) {
                                lh_list.push({ type: "", gid: 10, name: "排列五" });
                            }
                            if (this.$store.state.userinfo.power.includes("183")) {
                                lh_list.push({ type: "", gid: 53, name: "排列三" });
                            }
                            if (this.$store.state.userinfo.power.includes("186")) {
                                lh_list.push({ type: "", gid: 4, name: "福彩3D" });
                            }
                            // if (this.$store.state.userinfo.power.includes("189")) {
                            //     lh_list.push({ type: "", gid: 4, name: "七星彩" });
                            // }
                            if (lh_list.length > 0) {
                                lh_list[0].type = "primary";
                            }
                        }
                        //颜色默认
                        if (this.$store.state.userinfo.color.length < 14) {
                            this.$store.state.userinfo.color = "hsl(87, 97% ,28%)";
                        }
                        this.$store.state.userinfo.lh_list = lh_list;
                        //获取网站名称
                        this.axios.post("login/get_web_info").then((result) => {
                            // let website = result.data.msg.website;
                            if (result.data.status == 1 && result.data.msg.web_name != "") {
                                this.$store.state.web_name = result.data.msg.web_name;
                                this.axios.post("main/game_list").then((result) => {
                                    if (result.data.status === 200) {
                                        this.$store.state.trader_game_list = result.data.repsoneContent.trader_game_list;
                                        this.$store.state.trader_game_list.sort((a, b) => a.order - b.order);
                                        if (result.data.repsoneContent.trader_game_list.length > 0) {
                                            this.$store.state.instant_checked_gid = result.data.repsoneContent.trader_game_list[0].id;
                                            this.$store.state.instant_checked_gname = result.data.repsoneContent.trader_game_list[0].gname;
                                        }
                                        this.$store.state.operate_game_list = result.data.repsoneContent.operate_game_list;
                                        this.$store.state.operate_game_list.sort((a, b) => a.order - b.order);
                                        let list = result.data.repsoneContent.game_list;
                                        if (list.length > 0) {
                                            list.sort((a, b) => a.order - b.order);
                                            this.$store.state.game_list = list;
                                            this.$router.replace(`/home`);
                                        } else {
                                            this.$message.warning("暂无彩种信息，请联系管理");
                                        }
                                    }
                                });
                            }
                        });
                    } else if (result.data.status === 200 && (result.data.shortMessage === "第一次登陆需要修改密码" || result.data.shortMessage === "密码已重置请重新设置新密码")) {
                        let data = result.data.repsoneContent;
                        this.$store.state.url_query_info = "user=" + data.id + "&token=" + data.token;
                        this.first_change_pwd.txtid = result.data.repsoneContent.id;
                        this.first_change_pwd.user_id = result.data.repsoneContent.id;
                        this.first_change_pwd.salt = result.data.repsoneContent.salt;
                        this.first_change_pwd.username = this.form.user.user_name;
                        this.first_change_pwd.old_password = result.data.repsoneContent.old_password;
                        this.$alert(result.data.shortMessage, "安全提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                            callback: () => {
                                this.show_view = "changepass";
                            },
                        });
                    } else if (result.data.status === 200 && result.data.shortMessage === "第一次登陆需要绑定邮箱") {
                        let data = result.data.repsoneContent;
                        this.$store.state.url_query_info = "user=" + data.user_id + "&token=" + data.token;
                        this.$alert(result.data.shortMessage, "安全提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                            callback: () => {
                                this.show_view = "email";
                                this.email_type = "qq";
                                this.f_email = "";
                                this.back_user_data = data;
                            },
                        });
                    } else if (result.data.status === 200 && result.data.shortMessage === "第一次登陆需要绑定google") {
                        let data = result.data.repsoneContent;
                        this.$store.state.url_query_info = "user=" + data.user_id + "&token=" + data.token;
                        this.axios.post("user_version2/setup_second_auth").then((res) => {
                            if (res.data.status == 200) {
                                this.qrdata = res.data.repsoneContent;
                                this.$alert(result.data.shortMessage, "安全提示", {
                                    confirmButtonText: "确定",
                                    type: "warning",
                                    callback: () => {
                                        this.show_view = "google";
                                        this.$nextTick(() => {
                                            this.$refs["erciyanzheng"].auth = "";
                                            this.$refs["erciyanzheng"].get_info(this.qrdata);
                                        });
                                    },
                                });
                            }
                        });
                    } else if (result.data.shortMessage === "登录失败，账号或密码错误!" || result.data.shortMessage === "账号或密码错误") {
                        this.error_times += 1;
                        if (this.error_times >= 3) {
                            this.getcode();
                        }
                    } else if (result.data.shortMessage === "请输入正确的验证码") {
                        this.error_times = 3;
                        this.getcode();
                    }
                });
            }
        },
        getcode() {
            this.axios
                .get(`login/vcode`, {
                    responseType: "arraybuffer",
                })
                .then((result) => {
                    if (true) {
                        const bufferUrl = btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
                        this.codeimg = "data:image/png;base64," + bufferUrl;
                    }
                });
        },
        sub_change() {
            if (this.first_change_pwd.txtoldpwd == "") {
                return (this.err.txtoldpwd = { visible: true, content: "请输入旧密码" });
            }
            if (this.first_change_pwd.txtnewpwd == "" || !this.$filters.passwordReg(this.first_change_pwd.txtnewpwd)) {
                return (this.err.txtnewpwd = { visible: true, content: "新密码必须包含：大写字母、小写字母、数字，3种组合，8-20位" });
            }
            if (this.first_change_pwd.txtoldpwd == this.first_change_pwd.txtnewpwd) {
                return (this.err.txtnewpwd = { visible: true, content: "新密码不能和旧密码相同" });
            }
            if (this.first_change_pwd.txtnewpwdcf !== this.first_change_pwd.txtnewpwd) {
                return (this.err.txtnewpwdcf = { visible: true, content: "两次输入的新密码不一致" });
            }
            this.axios.post("user_version2/ResetPasswd", this.first_change_pwd).then((result) => {
                if (result.data.status == 200) {
                    this.$alert(result.data.shortMessage, "成功提示", {
                        confirmButtonText: "确定",
                        type: "success",
                        callback: () => {
                            this.form.user.passwd = this.first_change_pwd.txtnewpwdcf;
                            this.show_view = "login";
                        },
                    });
                }
            });
        },
        bd_email() {
            let user_email = this.f_email + `${this.email_type == "qq" ? "@qq.com" : "@gmail.com"}`;
            if (!/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(user_email)) {
                return this.$message.error("邮箱格式不正确，请重新输入");
            }
            this.$messageBox
                .confirm(`您将要绑定的邮箱：${user_email}`, "再次确认邮箱地址", {
                    confirmButtonText: "确认绑定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    this.axios
                        .post("user_version2/user_email_save", {
                            txtid: this.back_user_data.user_id,
                            email: user_email,
                        })
                        .then((result) => {
                            // console.log(result);
                            if (result.data.status == 200) {
                                this.$alert(result.data.shortMessage, "提示", {
                                    confirmButtonText: "确定",
                                    type: "success",
                                });
                                this.show_view = "login";
                            }
                        });
                })
                .catch(() => {
                    this.$message("已取消绑定");
                });
        },
        get_email_code() {
            if (this.form.user.user_name != "" && this.form.user.passwd != "") {
                this.get_emailcode_btn_text = "验证码发送中...";
                this.axios.post("login/send_email_code", this.form.user).then((result) => {
                    // console.log(result);
                    if (result.data.status == 200) {
                        this.$alert("验证码已经发送到您绑定的邮箱", result.data.shortMessage, {
                            confirmButtonText: "确定",
                            type: "success",
                        });
                        this.exp_time = ((result.data.repsoneContent.exp_time * 1000 - Date.now() + 1000) / 1000).toFixed(0) * 1;
                        let _this = this;
                        this.exp_time_loop_id = window.setInterval(() => {
                            _this.exp_time -= 1;
                            if (_this.exp_time <= 0) {
                                window.clearInterval(_this.exp_time_loop_id);
                                _this.exp_time = 0;
                                _this.get_emailcode_btn_text = "获取";
                            }
                        }, 1000);
                    }
                });
            } else {
                this.$message.error("请先输入账号密码，再获取验证码");
            }
        },
    },
};
</script>

<style scoped>
.login {
    background-image: url("../assets/loginbg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #fceab7;
    height: 100vh;
    overflow: hidden;
}

.input-box input {
    width: 100%;
    float: right;
    height: 40px;
    line-height: 40px;
    border: none;
    background: no-repeat;
    outline: 0;
    font-size: 20px;
    font-family: "Microsoft YaHei";
    padding-left: 3px;
}

.img-box img {
    vertical-align: middle;
}

.input-box {
    float: left;
    width: 84%;
}

.img-box {
    float: left;
    width: 16%;
    text-align: center;
    margin-top: 7px;
}

.user {
    overflow: hidden;
    margin: 6px auto;
    background: #fff;
    padding: 6px;
    border-radius: 6px;
    border: 1px solid #000;
}

/* .shenfen img {
    width: 400px;
    height: 35px;
} */
.loginbtn {
    display: block;
    text-align: center;
    margin: 8px 0 50px 0;
}

.loginbtn button {
    width: 450px;
    height: 55px;
    line-height: 55px;
    float: center;
    background: #3352a4;
    border-radius: 5px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    font-family: "Microsoft YaHei";
    cursor: pointer;
    border: none;
}

.usemsg {
    margin: 0 auto;
    overflow: hidden;
}

.tx,
.shenfen {
    text-align: center;
    padding: 1px 80px 10px 80px;
}

.loginbox {
    background: #fff;
    margin: 15% auto;
    overflow: hidden;
    border-radius: 13px;
}

.logintitle {
    color: #3352a4;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0 10px 0;
    overflow: hidden;
}

.login .panel .panel-body {
    padding: 3px;
}

.login .panel .panel-title {
    height: 28px;
    line-height: 28px;
    padding: 0 3px;
    text-align: left;
    background-image: linear-gradient(#fff, rgb(238, 255, 227));
    border-bottom: 1px solid #093;
}

.login .panel .panel-title h2 {
    font-size: 12px;
    font-weight: bold;
    background: url("../assets/title_icon.gif") no-repeat 0 8px;
    padding: 0 0 0 18px;
    margin: 0 0 0 5px;
    float: left;
}

.login .panel {
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 5px #ccc;
    background-image: linear-gradient(#fff, #fff);
    border: 1px solid #093;
    padding-bottom: 10px;
    margin: 10px auto;
}

.login .sub-menu {
    background-image: linear-gradient(#fff, #fff);
}

.myform {
    border: 1px solid #093;
    margin-top: 3px;
    line-height: 28px;
}

.myform .myform-item:hover .el-col {
    background-color: #ffff2b !important;
}

.myform-item-lable {
    text-align: right;
    padding: 0 10px;
    border-right: 1px solid #093;
    border-bottom: 1px solid #093;
}

.myform-item-value {
    padding: 0 10px;
    text-align: left;
    border-bottom: 1px solid #093;
    background-color: #fff;
}

.myform:nth-last-child(1),
.myform:nth-last-child(2) {
    border-bottom: 0;
}

.code1 {
    float: left;
    width: 55%;
}

.code1 input {
    width: 100%;
    float: right;
    height: 40px;
    line-height: 40px;
    border: none;
    background: 0 0;
    outline: 0;
    font-size: 20px;
    font-family: "Microsoft YaHei";
}

.code2 {
    float: left;
    text-align: right;
}

.code2 img {
    width: 106px;
    height: 40px;
    vertical-align: middle;
}

.img-box {
    float: left;
    width: 16%;
    text-align: center;
    margin-top: 7px;
}

.img-box img {
    vertical-align: middle;
}

.label-box {
    width: 30%;
    margin-top: 0;
}

.auth-label {
    background-color: #bbb;
    line-height: 40px;
    font-size: 16px;
    color: #000;
    font-weight: bold;
}

.auth-input {
    width: 68%;
    padding-left: 2%;
    background-color: #fff;
}
</style>
